/* You can add global styles to this file, and also import other style files */

$border-color: rgba($color: #8d8d8d, $alpha: 0.4);
$submit-button-background-color: #008bd0;
$submit-button-text-color: white;
$delete-button-background-color: #CC231E;
$warning-color: #d09900;
$error-color: #CC231E;

body {
    font-family: 'Segoe UI';
}

.styled-border {
    border-style: solid;
    border-color: $border-color;
    border-width: 1px;
    border-radius: 4px;
}
